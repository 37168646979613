import '../styles/templates/case-studies.scss'

import React from 'react'

import { PAGE_NAME } from '../../config/cms'
import { CaseStudiesList } from '../components/case-studies-list'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { usePageData } from '../hooks/cms/use-page'
import { PageComponentFactory } from '../utils/pages'

const TEMPLATE_NAME = PAGE_NAME.CaseStudies

const CaseStudiesPage = PageComponentFactory(() => {
  const { landingSection, strings } = usePageData(PAGE_NAME.CaseStudies)

  return (
    <Layout variant="secondary" buttonVariant='primary' page={TEMPLATE_NAME} pathname="/case-studies">
      <main className="case-studies-page">
        <section className="case-studies-page__landing-section">
          <div className="container">
            <PageHeading>{landingSection.heading}</PageHeading>
            <PageSubheading>{landingSection.caption}</PageSubheading>
          </div>
        </section>

        <section className="case-studies-page__list-section">
          <div className="container">
            <CaseStudiesList strings={strings} />
          </div>
        </section>
      </main>
    </Layout>
  )
})

export default CaseStudiesPage
